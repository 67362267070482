<template>
  <div class="container">
    <van-nav-bar
      title="确认订单"
      left-arrow
      @click-left="goBack"
      :fixed="true"
    />
    <div class="order-box">
      <div class="order-box_1">
        <div class="info-box  van-hairline--bottom">
          <div class="order-info">
            <div class="info">
              <div class="p1">
                <p class="txt space">订单编号</p>
                <p class="txt">{{ orderInfo.order_no }}</p>
              </div>
              <div class="p2 light">
                <p class="txt space">{{ orderInfo.zh_name }}:</p>
                <p class="txt">{{ orderInfo.create_at }}</p>
              </div>
            </div>
          </div>
          <div class="state-box van-hairline--left">
            <van-icon
              v-if="orderInfo.status == 1"
              class="iconfont"
              class-prefix="icon"
              name="order-unAccept"
              size="30"
              color="#333"
            />
          </div>
        </div>
        <div class="order-content van-hairline--bottom">
          <div class="p2 gray">
            <p class="txt-title space">服务需求:</p>
            <p class="txt">
              <!--
              <span
                v-for="(item, index) in orderInfo.order_dataArr"
                :key="index"
              >
                {{ item }}
              </span>
              -->
              {{orderInfo.remarks}}
            </p>
          </div>
          
           <div class="p2 gray">
            <p class="txt-title space">用工时长:</p>
            <p class="txt">{{ orderInfo.long_time }}</p>
          </div>
          <div class="p2 gray">
            <p class="txt-title space">数量:</p>
            <p class="txt">{{ orderInfo.order_num }}</p>
          </div>
          <div class="p2 gray">
            <p class="txt-title space">金额:</p>
            <p class="txt">{{ fmoney(orderInfo.real_price, 2) }} 元</p>
          </div>

          <div class="p2 gray">
            <p class="txt-title space">位置:</p>
            <p class="txt">{{ orderInfo.order_area }}</p>
          </div>
          <div class="p2 gray">
            <p class="txt-title space">备注:</p>
            <p class="txt">
              {{ orderInfo.remarks_text }}
            </p>
          
          </div>
        </div>
        <van-swipe
          class="my-swipe"
          :autoplay="3000"
          indicator-color="white"
          v-if="orderInfo.remarks_img != ''"
        >
          <van-swipe-item
            v-for="(img, index) in orderInfo.remarks_img"
            :key="index"
            @click="imagePrev"
          >
            <img :src="'https://jsagency.cn/' + img" />
          </van-swipe-item>
        </van-swipe>
        <div class="kbox"></div>
        <div class="p-container van-hairline--bottom">
          <div class="info-content">
            <div class="avator">
               <img v-if="orderInfo.headimg != ''" :src="orderInfo.headimg" />
              <img :src="defaultAvatar" v-else />
            </div>
            <div class="d-info">
              <div class="p2">
                <p class="txt">{{ orderInfo.express_name }}</p>
              </div>
              <div class="p2">
                <p class="txt space">联系电话:</p>
                <p class="txt">
                  {{
                    orderInfo.status == 1
                      ? "***********"
                      : orderInfo.express_phone
                  }}
                </p>
              </div>
              <div class="p2">
                <p class="txt space">联系地址:</p>
                <p class="txt">{{ orderInfo.express_address }}</p>
              </div>
            </div>
          </div>
          <div class="contract-box">
            <van-icon
              v-if="orderInfo.status == 3 || orderInfo.status == 2"
              name="phone-o"
              size="30"
              color="#F3C301"
              @click="phoneCall(orderInfo.express_phone)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="steps-box">
      <van-steps active-color="#F3C301" direction="vertical" active="0">
        <van-step v-for="(item, index) in steps" :key="index">
          <P>{{ item.create_time }}</P>
          <p>{{ item.content }}</p>
        </van-step>
      </van-steps>
    </div>
    <div class="empty"></div>
    <div class="action-bar">
      <div class="button">
        <van-button
          icon="phone-o"
          plain
          type="info"
          block
          style="width: 375rpx;height:90;border:0;border-raduis:0"
          color="#F3C301"
          @click="contact('18883251958')"
          ><p style="color: #333">联系客服</p>
        </van-button>
      </div>
      <div class="button van-hairline--left">
        <van-button
          type="info"
          v-if="orderInfo.status == 1"
          block
          style="width:375rpx;height:90;border:0"
          color="#F3C301"
          @click="acceptOrder(orderInfo.id)"
        >
          <p style="color: #333;font-weight: 800">确认接单</p>
        </van-button>
        <van-button
          type="info"
          v-if="orderInfo.status == 2"
          block
          style="width:375rpx;height:90;border:0"
          color="#F3C301"
          @click="startService(orderInfo.id)"
        >
          <p style="color: #333;font-weight: 800">去服务</p>
        </van-button>
        <van-button
          type="info"
          v-if="orderInfo.status == 3"
          block
          style="width:375rpx;height:90;border:0"
          color="#F3C301"
        >
          <p style="color: #333;font-weight: 800">服务中</p>
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { ImagePreview } from "vant";
import ServiceCateService from "@/api/service";

export default {
  data() {
    return {
      showService: false,
      defaultAvatar: require("@/assets/img/default.png"),
      orderInfo: null,
      steps: [],
    };
  },
  async beforeMount() {
    var params = {
      orderid: this.$route.params.orderId,
    };
    const resp = await ServiceCateService.staffOrderDetail(this, params);
    if (resp.data.code == 10000) {
      this.orderInfo = resp.data.data;
      this.steps = resp.data.data.log;
      console.log(this.orderInfo);
    }
  },
  methods: {
    imagePrev: function() {
      var fileList = new Array();
      var list = this.orderInfo.remarks_img;
      list.forEach(function(item) {
        fileList.push("https://jsagency.cn/" + item);
      });
      ImagePreview({
        images: fileList,
      });
    },
    goBack: function() {
      this.$router.go(-1);
    },
    async startService(orderId) {
      var params = {
        orderId: orderId,
        token: this.$cookie.get("token"),
        //token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
      };
      const resp = await ServiceCateService.staffServiceOrder(this, params);
      if (resp.data.code == 10000) {
        this.$router.push("/index"); //去服务直接跳转到列表
        return;
        /*
        console.log(resp.data.data);
        //接单成功,更新状态
        Toast("您已开始服务,请注意更新服务进度!");
        var params1 = {
          orderid: orderId,
        };
        const resp1 = await ServiceCateService.staffOrderDetail(this, params1);
        if (resp1.data.code == 10000) {
          this.orderInfo = resp1.data.data;
          this.steps = resp1.data.data.log;
          console.log(resp1.data.data);
        }
        */
      }
    },
    async acceptOrder(orderId) {
      var params = {
        orderId: orderId,
        token: this.$cookie.get("token"),
        //token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
      };
      const resp = await ServiceCateService.staffGetOrder(this, params);
      if (resp.data.code == 10000) {
        console.log(resp.data.data);
        //接单成功,更新状态
        Toast("恭喜您,接单成功,请尽快开始服务!");
        var params1 = {
          orderid: this.$route.params.orderId,
        };
        const resp1 = await ServiceCateService.staffOrderDetail(this, params1);
        if (resp1.data.code == 10000) {
          this.orderInfo = resp1.data.data;
          this.steps = resp1.data.data.log;
          console.log(resp1.data.data);
        }
      } else {
        Toast(resp.data.msg);
      }
    },
    phoneCall: function(phone) {
      window.location.href = "tel://" + phone;
    },
    contact: function(phone) {
      window.location.href = "tel://" + phone;
    },
    fmoney(s, n) {
      n = n > 0 && n <= 20 ? n : 2;
      s = parseFloat((s + "").replace(/[^\d\\.-]/g, "")).toFixed(n) + "";
      var l = s
          .split(".")[0]
          .split("")
          .reverse(),
        r = s.split(".")[1];
      var t = "";
      for (var i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
      }
      return (
        t
          .split("")
          .reverse()
          .join("") +
        "." +
        r
      );
    },
  },
};
</script>

<style scoped>
.my-swipe {
  height: 200px;
  width: 100%;
}
.my-swipe img {
  width: 100%;
  height: 100%;
}
.empty {
  height: 46px;
}
.container {
  background-color: #fafafa;
}

.order-box {
  background: #fff;
  padding-left: 16px;
  padding-right: 16px;
  margin: 46px 0 0 0;
}

.order-box_1 {
  padding: 10px 0 8px 0;
}

.order-box_1 .order-content {
  margin-top: 8px;
  padding-bottom: 10px;
}
.order-box_1 .order-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 8px;
}
.order-box_1 .info-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.info-box .state-box {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.state-box img {
  width: 80%;
  height: 80%;
}
.state-box .txt {
  font-size: 12px;
  color: #b3b3b3;
}

.order-box_1 .p1 {
  color: #333;
  font-size: 14px;
  padding-top: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.order-box_1 .p2 {
  color: #333;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.txt-title {
  width: 58px;
}
.p1.light {
  color: #b3b3b3;
}

.p2.light {
  color: #b3b3b3;
}
.p2.gray {
  color: #808080;
}

.order-box_1 .p1 .txt {
  font-size: 14px;
}

.order-box_1 .p2 .txt {
  font-size: 12px;
  max-width: 260px;
}

.p-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.p-container .info-content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.info-content .avator {
  border: solid 1px #f5f5f5;
  border-radius: 2px;
}
.avator img {
  width: 80px;
  height: 80px;
  border-radius: 2px;
}
.info-content .d-info {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px;
}
.space {
  margin-right: 10px;
}
.d-info .p1 .txt {
  font-size: 14px;
  color: #333;
}
.d-info .p2 .txt {
  font-size: 12px;
  color: #333;
}
.d-info .p3 .txt {
  font-size: 12px;
  color: #f3c301;
  font-weight: 700;
}

.contract-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
}
.action-bar {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-top: solid 1px #fafafa;
  width: 100%;
  height: 45px;
  z-index: 9999;
}
.action-bar .button {
  width: 50%;
  height: 45px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.button .txt {
  font-size: 14px;
  color: #333333;
}
.action-bar .price-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}
.price-box .label {
  font-size: 12px;
  color: #333333;
}

.price-box .price {
  font-size: 14px;
  font-weight: 700;
  color: #f3c301;
}

.space {
  margin-right: 10px;
}
.van-hairline--bottom,
.van-hairline--left {
  color: #fafafa !important;
}

.van-step {
  font-size: 12px !important;
}

.avator-box {
  display: flex;
  background-color: #ffffff;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
}
.avator-box .pName {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}
.avator-box .pTag {
  font-size: 12px;
  color: #f3c301;
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #fff6d2;
  margin-bottom: 10px;
}
.cellVClass {
  text-align: left !important;
}
</style>
